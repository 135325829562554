<template>
  <b-card header-tag="header" header-text-variant="primary" body-class="px-2">
    <!-- HEADER -->
    <template #header>
      <b-card-title>
        Global Products
        <!-- New -->
        <b-icon-plus-circle @click="addNew" scale="1.5" class="float-right" />
      </b-card-title>

      <!-- Search -->
      <b-form-input
        type="search"
        id="search"
        v-model="search"
        @update="onFilterChange"
        placeholder="Search"
        class="mt-4"
        trim
      />
    </template>

    <b-table
      id="global-product-list-table"
      :busy.sync="isBusy"
      :items="itemsProvider"
      :fields="fields"
      :per-page="perPageCount"
      :current-page="currentPage"
      striped
      hover
    >
      <!-- Busy State -->
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong> Loading...</strong>
        </div>
      </template>

      <!-- Custom cells -->
      <template #cell(edit)="data">
        <b-icon-pencil-fill @click="editIconClicked(data.item)" />
      </template>
      <template #cell(delete)="data">
        <b-icon-trash-fill @click="deleteIconClicked(data.item)" />
      </template>
    </b-table>

    <!-- Pagination -->
    <div>
      <b-pagination
        :disabled="isBusy"
        v-model="currentPage"
        :total-rows="count"
        :per-page="perPageCount"
        align="center"
      ></b-pagination>
    </div>
  </b-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  deleteProduct,
  getProducts,
  getProductsCount,
} from "../../../../apis/admin";

export default {
  created() {
    this.initCount();
  },
  data() {
    return {
      // busy state
      isBusy: false,

      // search
      search: "",

      // table
      fields: [
        { key: "id", sortable: true },
        { key: "name", sortable: true },
        "unit",
        { key: "edit", label: "" },
        { key: "delete", label: "" },
      ],

      // for pagination
      currentPage: 1,
      perPageCount: 20,
      count: 0,
    };
  },
  computed: {
    ...mapGetters({
      adminToken: "admin/getToken",
      products: "admin/globalProducts/getList",
    }),
  },
  methods: {
    ...mapActions({
      setProducts: "admin/globalProducts/setList",
      removeProduct: "admin/globalProducts/removeItem",
    }),
    addNew() {
      this.$router.push({ name: "GlobalProductForm" });
    },
    initCount() {
      // get count for pagination, api of products will be handled via pagination
      getProductsCount(this.adminToken, { name: this.search }).then((data) => {
        this.count = data.count;
      });
    },
    async itemsProvider({ currentPage, perPage, sortBy, sortDesc }) {
      const pageable = {
        page: currentPage - 1,
        size: perPage,
        sort: sortBy ? (sortDesc ? `${sortBy},desc` : sortBy) : "",
      };
      const params = { ...pageable, name: this.search };
      try {
        return await getProducts(this.adminToken, params);
      } catch (_) {
        return [];
      }
    },
    onFilterChange(newValue) {
      this.search = newValue;
      // get the new count & refresh the table
      this.initCount();
      this.$root.$emit("bv::refresh::table", "global-product-list-table");
    },
    editIconClicked(item) {
      this.$router.push({
        name: "GlobalProductForm",
        query: { productId: item.id },
      });
    },
    deleteIconClicked(item) {
      // show confirm modal to delete
      this.$bvModal
        .msgBoxConfirm("Confirm Delete?", {
          size: "sm",
          buttonSize: "sm",
          okVariant: "secondary",
          okTitle: "YES",
          cancelVariant: "primary",
          cancelTitle: "NO",
          centered: true,
        })
        .then((value) => {
          // on confirm delete, delete item
          if (value) {
            // delete item, on success delete, remove item from list
            deleteProduct(item.id).then(() => {
              this.removeProduct(item).then(() => {
                // refresh the table
                this.$root.$emit(
                  "bv::refresh::table",
                  "global-product-list-table"
                );
              });
            });
          }
        });
    },
  },
};
</script>

<style></style>
